import React from 'react';
import useProfile, { ProfileHookData } from 'components/private/hooks/useProfile';

export const ProfileContext = React.createContext<ProfileHookData>({
  removeHike: () => {
    return;
  },
});

export const ProfileProvider = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
  // only active hikes
  return (
    <ProfileContext.Provider value={{ ...useProfile(true) }}>{children}</ProfileContext.Provider>
  );
};
