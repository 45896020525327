import React, { useEffect, useState } from 'react';
import { Policy } from 'cookie-though/dist/types/types';
import { useIntl } from 'react-intl';

// const ESSENTIAL = 'essential';
export const PERSONAL = 'personal';
export const ANALYTICS = 'statistics';

// category: 'essential' | 'social' | 'personalisation' | 'marketing' | 'statistics' | 'functional';

// const essentialPolicy = {
//   id: ESSENTIAL,
//   label: 'Necessary Cookies',
//   description: 'This allows us to offer you a working website',
//   category: 'essential',
// } as Policy;

type CookieConsent = {
  analyticsAllowed: boolean;
  personalSiteAllowed: boolean;
};

const initialState: CookieConsent = {
  analyticsAllowed: false,
  personalSiteAllowed: false,
};

export const CookieConsentContext = React.createContext<CookieConsent>(initialState);

export const CookieConsentProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const {
    isPersonalSiteAllowed,
    isAnalyticsAllowed,
    onPersonalSiteAllowedChange,
    onAnalyticsAllowedChange,
    applyLanguage,
  } =
    typeof window !== 'undefined'
      ? require('../../config/cookies/CookieConsent')
      : {
          isAnalyticsAllowed: () => false,
          isPersonalSiteAllowed: () => false,
          onPersonalSiteAllowedChange: (executeFn: (isAllowed: boolean) => void) =>
            executeFn(false),
          onAnalyticsAllowedChange: (executeFn: (isAllowed: boolean) => void) => executeFn(false),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          applyLanguage: () => {},
        };

  const [analyticsAllowed, setAnalyticsAllowed] = useState<boolean>(isAnalyticsAllowed());
  const [personalSiteAllowed, setPersonalSiteAllowed] = useState<boolean>(isPersonalSiteAllowed());
  const { formatMessage } = useIntl();

  const personalPolicy = {
    id: PERSONAL,
    label: formatMessage({
      defaultMessage: 'Personal Cookies',
      description: 'cookies/personal/title',
    }),
    category: 'personalisation',
    description: formatMessage({
      defaultMessage:
        'This allows us to offer you a personalised navigation, based on your previous visits',
      description: 'cookies/personal/description',
    }),
  } as Policy;

  const analyticsPolicy = {
    id: ANALYTICS,
    label: formatMessage({
      defaultMessage: 'Analytics',
      description: 'cookies/statistics/title',
    }),
    category: 'statistics',
    description: formatMessage({
      defaultMessage: 'This allows us to improve your experience',
      description: 'cookies/statistics/description',
    }),
  } as Policy;

  useEffect(() => {
    const config = {
      policies: [personalPolicy, analyticsPolicy],
      permissionLabels: {
        accept: formatMessage({
          defaultMessage: 'Accept',
          description: 'cookies/permissions/accept',
        }),
        acceptAll: formatMessage({
          defaultMessage: 'Accept all',
          description: 'cookies/permissions/acceptAll',
        }),
        decline: formatMessage({
          defaultMessage: 'Decline',
          description: 'cookies/permissions/decline',
        }),
      },
      cookiePreferenceKey: 'cookie-preferences',
      header: {
        title: formatMessage({
          defaultMessage: 'Cookies',
          description: 'cookies/title',
        }),
        subTitle: formatMessage({
          defaultMessage: 'Please accept for a working website.',
          description: 'cookies/subtitle',
        }),
        description: formatMessage({
          defaultMessage: 'We use cookies to guarantee you a better experience.',
          description: 'cookies/description',
        }),
      },
      cookiePolicy: {
        url: 'https://hitchandhike.com/cookie-policy',
        label: formatMessage({
          defaultMessage: 'Cookie Policy',
          description: 'cookies/link/label',
        }),
      },
      customizeLabel: formatMessage({
        defaultMessage: 'Customize',
        description: 'cookies/extra',
      }),
    };

    applyLanguage(config);
  }, []);

  useEffect(() => {
    onPersonalSiteAllowedChange((isAllowed: boolean) => {
      setPersonalSiteAllowed(isAllowed);
    });
    onAnalyticsAllowedChange((isAllowed: boolean) => {
      setAnalyticsAllowed(isAllowed);
    });
  }, []);

  return (
    <CookieConsentContext.Provider
      value={{
        analyticsAllowed,
        personalSiteAllowed,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
