import useAuthentication, {
  Authentication,
} from 'components/private/Login/authentication/useAuthentication';
import React from 'react';
import firebase from 'firebase/app';

export const UserAuthenticationContext = React.createContext<Authentication>({});

export const UserAuthenticationProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const { authLoading, user, userData, admin } = useAuthentication();

  return (
    <UserAuthenticationContext.Provider
      value={{
        authLoading,
        user,
        userData,
        admin,
      }}
    >
      {children}
    </UserAuthenticationContext.Provider>
  );
};

export function isUserLoggedIn(user?: firebase.User): boolean {
  return !!(user && user.uid && !user.isAnonymous);
}
