export type HikeClientError = {
  message: string;
  code: ErrorCode;
  description?: string;
  type: ErrorType;
};

export enum ErrorCode {
  NO_SUPPORT = 'NO_SUPPORT',
  UNKNOWN = 'UNKNOWN',
}

export enum ErrorType {
  HIKE = 'HIKE',
  HIKE_API = 'HIKE_API',
  UNKNOWN = 'UNKNOWN',
}

export const ErrorCodeTranslations = {
  NO_SUPPORT: 'This use case is not yet supported',
  UNKNOWN: 'Unknown error',
};

export type HikeErrorConstructor = { code: ErrorCode; message: string; description?: string };
export type HikeApiErrorConstructor = { httpStatusCode: number } & HikeErrorConstructor;

export class HikeError extends Error {
  public type: ErrorType;
  public readonly message: string;
  public readonly code: ErrorCode;
  public readonly description?: string;

  constructor({ code, message, description }: HikeErrorConstructor) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);

    this.type = ErrorType.HIKE;
    this.message = message;
    this.code = code;
    this.description = description;

    Error.captureStackTrace(this);
  }

  toClientError(): HikeClientError {
    return {
      message: this.message,
      code: this.code,
      description: this.description,
      type: this.type,
    };
  }
}

export function formatMessage(code: ErrorCode): string {
  return ErrorCodeTranslations[code] || ErrorCodeTranslations[ErrorCode.UNKNOWN];
}

export function toHikeError({ code, message }: HikeClientError): HikeError {
  return new HikeError({ code, message });
}

//free to extend the BaseError
export class HikeAPIError extends HikeError {
  public readonly httpStatusCode: number;

  constructor({ httpStatusCode, ...hikeErrorConstructor }: HikeApiErrorConstructor) {
    super(hikeErrorConstructor);
    this.httpStatusCode = httpStatusCode;
    this.type = ErrorType.HIKE_API;
  }
}
