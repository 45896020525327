import handleFetchResponse from 'services/utils/handleFetchResponse';
import firebase from 'firebase/app';
import { HikeIdentifier } from './types/hike/Hike';
import { ErrorCode, HikeError } from './types/hike/HikeError';
import * as Sentry from '@sentry/node';
import { Preferences } from './types/Choices';

export function handleHikeError(e: HikeError | Error): ResponseType<any> {
  if (e instanceof HikeError) {
    return {
      error: e,
      isLoading: false,
      isError: true,
    };
  }
  // This cannot be the case, due to error that should always be of type ErrorType
  Sentry.captureException('Found error different then type HikeError');
  return {
    error: new HikeError({ code: ErrorCode.UNKNOWN, message: e.toString() }),
    isLoading: false,
    isError: true,
  };
}
// https://kentcdodds.com/blog/using-fetch-with-type-script
export async function createHike(
  hitchCreate: HikeIdentifier,
  user: firebase.User
): Promise<ResponseType<string>> {
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify(hitchCreate),
  });
  try {
    return { data: (await handleFetchResponse(response)).id, isLoading: false, isError: false };
  } catch (e) {
    return handleHikeError(e);
  }
}

export type ResponseType<Data> = {
  isLoading: boolean;
  isError: boolean;
  error?: HikeError;
  data?: Data;
};

export async function createHikeEntry(
  hikeId: string,
  triggerObjectId: string,
  type: 'product' | 'order' | 'draft',
  user: firebase.User
): Promise<ResponseType<string>> {
  const idToken = await user.getIdToken();
  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike/${hikeId}/manual`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify({ triggerObjectId, type }),
  });
  try {
    return { data: (await handleFetchResponse(response)).id, isLoading: false, isError: false };
  } catch (e) {
    return handleHikeError(e);
  }
}

export async function removeHike(hikeId: string, user: firebase.User): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike/${hikeId}`, {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  });
  return await handleFetchResponse(response);
}

export async function pauseHike(hikeId: string, user: firebase.User): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike/${hikeId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify({ status: 'pause' }),
  });
  return (await handleFetchResponse(response)).id;
}

export async function activateHike(
  hikeId: string,
  user: firebase.User,
  billingPackage?: string
): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike/${hikeId}/activate`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify({
      package: billingPackage,
    }),
  });
  const handledResponse = (await handleFetchResponse(response)) as { redirectUrl?: string };
  if (handledResponse && handledResponse.redirectUrl) {
    window.location.href = handledResponse.redirectUrl;
  }
}

export async function savePreferences(
  hikeId: string,
  preferences: Preferences,
  user: firebase.User
): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/hike/${hikeId}/preferences`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify({ preferences }),
  });
  return (await handleFetchResponse(response)).id;
}
