import { useContext } from 'react';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';
import firebase from 'firebase/app';
import * as hikeService from 'services/hitchit';
import useHikes from 'services/firebase/hooks/useHikes';
import { Hike } from 'services/hitchit/types/hike/Hike';

export type ProfileHookData = {
  user?: firebase.User;
  hikes?: Hike[];
  removeHike: (hikeId: string) => void;
  loading?: boolean;
  authLoading?: boolean;
};
export default function useProfile(onlyActive?: boolean): ProfileHookData {
  const { user, authLoading } = useContext(UserAuthenticationContext);

  const { hikes, loading } = useHikes(user?.uid, onlyActive);
  const removeHike = async (hikeId: string) => {
    if (user && hikeId) {
      await hikeService.removeHike(hikeId, user);
    }
  };

  return {
    user,
    hikes,
    // if one of the two is loading: it is loading :-)
    loading: !!authLoading || !!loading,
    authLoading,
    removeHike,
  };
}
