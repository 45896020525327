// import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import * as Sentry from '@sentry/node';
import { Hike } from 'services/hitchit/types/hike/Hike';

export default function useHikes(
  uid?: string,
  // only the active ones (active/pause ; not draft ; not deleted)
  onlyActive?: boolean
): {
  hikes: Hike[] | undefined;
  loading: boolean;
  error?: string;
} {
  const [hikes, loading, error] = useCollectionData<Hike>(
    uid
      ? firebase.firestore().collection('hikes').where('users', 'array-contains', uid)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      transform: ({ createdAt, activationDate, ...rest }: any) => ({
        createdAt: createdAt.toDate(),
        activationDate: activationDate && activationDate.toDate(),
        ...rest,
      }),
    }
  );

  if (error) {
    console.error(error.message);
    Sentry.captureException(error.message);
    return { hikes, loading, error: error.message };
  }

  const filteredHikes = hikes
    ? hikes.filter((hike) => {
        if (onlyActive) {
          return hike.status === 'active' || hike.status === 'pause';
        }
        return hike.status !== 'deleted';
      })
    : undefined;

  return { hikes: filteredHikes, loading };
}
