import firebase from 'firebase/app';
import handleFetchResponse from 'services/utils/handleFetchResponse';

export default async function login(user?: firebase.User): Promise<{ admin: boolean }> {
  if (!user) {
    throw new Error('cannot login without firebase user');
  }
  const idToken = await user.getIdToken();

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/login`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
  });

  return handleFetchResponse(response);
}
