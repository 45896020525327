import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import login from 'services/authentication/login';
import 'firebase/firestore';
import * as Sentry from '@sentry/node';
import useUser, { UserData } from 'services/firebase/hooks/useUser';
import { useRouter } from 'next/router';

export type Authentication = {
  authLoading?: boolean;
  user?: firebase.User;
  userData?: UserData;
  admin?: boolean;
};
export default function useAuthentication(): Authentication {
  const { query, pathname, replace } = useRouter();
  const [user, authLoading, error] = useAuthState(firebase.auth()) as [
    firebase.User | undefined,
    boolean,
    firebase.auth.Error | undefined
  ];
  const [admin, setAdmin] = useState(false);

  const uid = user?.uid;
  const { user: hhUser, loading: hhLoadingUser, error: hhError } = useUser(uid);

  useEffect(() => {
    if (query.jwt && !authLoading) {
      const { jwt, ...queryWithoutJwt } = query;
      if (uid) {
        firebase.auth().signOut();
      }
      replace({ pathname, query: queryWithoutJwt }, undefined, { shallow: true });
      firebase.auth().signInWithCustomToken(jwt as string);
    }
  }, [query, uid, authLoading, pathname, replace]);

  useEffect(() => {
    // for Shopify: https://community.shopify.com/c/Shopify-APIs-SDKs/Authenticating-Shopify-app-with-Firebase/td-p/779799/page/2
    async function authenticate(): Promise<void> {
      try {
        const response = await login(user);
        if (response.admin) {
          setAdmin(true);
        }
      } catch (e) {
        console.error('Failed to sign in', e);
        Sentry.captureException(`Failed to sign in ${e}`);
        firebase.auth().signOut();
      }
    }

    if (authLoading || hhLoadingUser) {
      return;
    }

    // No support for anonymous users anymore
    if (user?.isAnonymous) {
      firebase.auth().signOut();
    }

    if (
      user &&
      user.uid &&
      !user.isAnonymous &&
      !authLoading &&
      !hhUser &&
      !hhLoadingUser &&
      !error &&
      !hhError
    ) {
      // first time user, still need to authenticate
      authenticate();
    }

    if (error || hhError) {
      // TODO handlethis error
      const daError = error ? { error } : { hhError };
      console.error('error in firebase app', daError);
      Sentry.captureException(`error in firebase app ${daError}`);
      firebase.auth().signOut();
    }
  }, [user, error, hhError, authLoading, hhLoadingUser, hhUser]);

  return {
    // as long as there is a jwt in the header, dont propagate the user object further through
    user: query.jwt ? undefined : user,
    authLoading,
    admin,
  };
}
