import * as Sentry from '@sentry/node';
import {
  ErrorCode,
  ErrorType,
  HikeClientError,
  toHikeError,
} from 'services/hitchit/types/hike/HikeError';

async function parseJsonResponse(response: Response): Promise<Record<string, string> | string> {
  try {
    return await response.json();
  } catch (e) {
    return {};
  }
}

export default async function handleFetchResponse(response: Response): Promise<any> {
  if (response.status >= 200 && response.status <= 299) {
    const responseBody = await response.text();
    try {
      if (responseBody) {
        return JSON.parse(responseBody);
      }
      return responseBody;
    } catch (_e) {
      return responseBody;
    }
  }

  async function createError() {
    const bodyResponse = await parseJsonResponse(response);

    switch (typeof bodyResponse) {
      case 'object': {
        if (bodyResponse.type && bodyResponse.type === ErrorType.HIKE) {
          return toHikeError(bodyResponse as HikeClientError);
        }
        return toHikeError({
          code: ErrorCode.UNKNOWN,
          message: JSON.stringify(bodyResponse),
          type: ErrorType.UNKNOWN,
        });
      }
      case 'string':
      default:
        return toHikeError({
          code: ErrorCode.UNKNOWN,
          message: bodyResponse || response.statusText,
          type: ErrorType.UNKNOWN,
        });
    }
  }

  const error = await createError();

  Sentry.captureException(error);
  throw error;
}
