// import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import * as Sentry from '@sentry/node';
import { HikeRef } from 'services/hitchit/types/hike/Hike';

export type HitchData = {
  lastVisit: number;
  totalVisits: number;
};

export type UserHitches = {
  [key: string]: HitchData;
};

export type BaseUserData = {
  uid: string;
  email?: string;
  /**
   * The photo URL for the user to whom the ID token belongs, if available.
   */
  picture?: string;
  sign_in_provider: string;
  identities: {
    [key: string]: string | Record<string, string>;
  };
};

export type UserData = BaseUserData & {
  hitches: UserHitches;
  createdAt: Date;
  hikeRefs?: HikeRef[];
};

export default function useUser(
  uid?: string
): {
  user?: UserData;
  loading: boolean;
  error?: string;
} {
  const [user, loading, error] = useDocumentData<UserData>(
    uid ? firebase.firestore().collection('users').doc(uid) : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      transform: ({ createdAt, ...rest }: any) => ({
        createdAt: createdAt?.toDate(),
        ...rest,
      }),
    }
  );

  if (error) {
    console.error(error.message);
    Sentry.captureException(error.message);
    return { user, loading, error: error.message };
  }

  return { user, loading, error };
}
