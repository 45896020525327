// import { enableAnonymousLogin } from 'config/firebase/initialize';
import { getPreferences, onPreferencesChanged, init } from 'cookie-though';
import { Config, Policy } from 'cookie-though/dist/types/types';

const PERSONAL = 'personal';
const ANALYTICS = 'statistics';

const personalPolicy = {
  id: PERSONAL,
  label: 'Personal Cookies',

  category: 'personalisation',
  description:
    'This allows us to offer you a personalised navigation, based on your previous visits',
} as Policy;

const analyticsPolicy = {
  id: ANALYTICS,
  label: 'Analytics',
  category: 'statistics',
  description: 'This allows us to improve your experience',
} as Policy;

const config = {
  policies: [personalPolicy, analyticsPolicy],
  permissionLabels: {
    accept: 'Accept',
    acceptAll: 'Accept all',
    decline: 'Decline',
  },
  cookiePreferenceKey: 'cookie-preferences',
  header: {
    title: 'Cookies',
    subTitle: 'Please accept for a working website.',

    description: 'We use cookies to guarantee you a better experience.',
  },
  cookiePolicy: { url: 'https://hitchandhike.com/cookie-policy', label: 'Cookie Policy' },
  customizeLabel: 'Customize',
};

init(config);

onPreferencesChanged(() => {
  handleCookiePreferenceChanges();
});

function handleCookiePreferenceChanges() {
  const isAnalyticsAllowed = isCookieTypeEnabled(ANALYTICS);
  // const isPersonalSiteAllowed = isCookieTypeEnabled(PERSONAL);

  if (isAnalyticsAllowed) {
    // enabling analytics from the start, doesnt seem to generate cookies
    // enableAnalytics();
  }
}

export function isPersonalSiteAllowed(): boolean {
  return isCookieTypeEnabled(PERSONAL);
}

export function isAnalyticsAllowed(): boolean {
  return isCookieTypeEnabled(ANALYTICS);
}

function isCookieTypeEnabled(id: string): boolean {
  return (
    getPreferences().cookieOptions.findIndex((option) => {
      if (option.id === id && option.isEnabled) {
        return true;
      }
      return false;
    }) !== -1
  );
}

export function onPersonalSiteAllowedChange(executeFn: (isAllowed: boolean) => any): void {
  onPreferencesChanged(() => {
    executeFn(isPersonalSiteAllowed());
  });
}

export function onAnalyticsAllowedChange(executeFn: (isAllowed: boolean) => any): void {
  onPreferencesChanged(() => {
    executeFn(isAnalyticsAllowed());
  });
}

export function applyLanguage(config: Config): void {
  init(config);
}
